<template>
  <div class="login-page pa-1 pa-sm-3 fill-height">
    <v-form @submit.prevent="submit">
      <v-card class="pa-2">

        <v-card-title>
          Willkommen beim {{ appTitle }}!
        </v-card-title>

        <v-card-text class="pt-3">
          <p>
            <v-text-field
              v-model="username"
              ref="username"
              label="E-Mail"
              validate-on-blur
              :rules="[rules.required, rules.email]"
              :readonly="isLoading"
            ></v-text-field>
          </p>
          <p>
            <v-text-field
              v-model="password"
              ref="password"
              label="Passwort"
              validate-on-blur
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :readonly="isLoading"
              :rules="[rules.required]"
              @click:append="showPassword = !showPassword"
              @blur="showPassword = false"
            ></v-text-field>
          </p>
          <p
            v-if="error"
            class="error--text text-center"
            v-text="error"
          />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            type="submit"
            :disabled="!canSubmit"
            :loading="isLoading"
          >
            Anmelden
          </v-btn>
          <small class="ml-3">
            oder
            <router-link :to="isLoading ? '' : {name: 'reset-password'}">neues Passwort</router-link>
          </small>
        </v-card-actions>

        <v-card-actions class="justify-center mt-6">
          <small>
            Noch nicht registriert?
            <router-link :to="isLoading ? '' : {name: 'registration'}">Jetzt registrieren</router-link>
          </small>
        </v-card-actions>

      </v-card>
    </v-form>
  </div>
</template>


<script>
import { APP_TITLE } from '@/config'
import { required, email } from '@/helpers/validators'
import { isEmailValid } from '@/helpers/email'

export default {
  name: 'LoginPage',
  data: () => ({
    appTitle: APP_TITLE,
    username: '',
    password: '',
    showPassword: false,
    rules: {required, email}
  }),
  computed: {
    isLoading() {
      return !!this.$store.state.auth.status.isLoggingIn
    },
    canSubmit() {
      const { username, password } = this
      return !!username && isEmailValid(username) && !!password
    },
    error() {
      const { error, reason } = this.$store.state.auth.status
      return error && reason || null
    }
  },
  created() {
    this.restoreUsername()
  },
  mounted() {
    this.focusFirstEmptyInput()
  },
  methods: {
    restoreUsername() {
      this.username = this.$route.query.u || ''
    },
    focusFirstEmptyInput() {
      const target = this.username ? 'password' : 'username'
      this.$refs[target].focus()
    },
    async submit() {
      const { username, password } = this
      const { dispatch } = this.$store
      try {
        await dispatch('auth/login', {username, password})
        this.$router.push(this.$route.query.to || '/')
      } catch {
        this.password = ''
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.login-page {
  max-width: 474px;
  margin: 0 auto;
  overflow: auto;

  .v-card__title {
    word-break: initial;
  }
}

@media screen and (min-width: 1264px) and (min-height: 600px) {
  .login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 474px;
  }
}
</style>
